import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ESTANCIA } from '../constant';

@Injectable({
  providedIn: 'root',
})
export class PropietarioService extends BaseService {
  url = `${this.apiUrl}propietario`;

  constructor(private http: HttpClient) {
    super();
  }

  getPropietario(propietarioId: number): Observable<any> {
    this.url = this.getUrl('propietario');
    const propietario = propietarioId.toString();
    return this.http.get<any>(`${this.url}/${propietario}`);
  }

  getList(): Observable<any[]> {
    this.url = this.getUrl('propietario');
    return this.http.get<any[]>(`${this.url}`);
  }

  getPaginate(skip: number = 0, limit: number = 0, sortBy: string, direction: string): Observable<any[]> {
    this.url = this.getUrl('propietario');
    let toGet = `${this.url}/paginate/?skip=${skip}&limit=${limit}`;
    toGet = sortBy && direction ? toGet.concat(`&sort_by=${sortBy}&direction=${direction}`) : toGet;
    return this.http.get<any[]>(toGet);
  }

  savePropietario(formData): Observable<any> {
    this.url = this.getUrl('propietario');
    return this.http.post<any>(`${this.url}/`, formData);
  }

  updatePropietario(propietarioId: number, formData): Observable<any> {
    this.url = this.getUrl('propietario');
    return this.http.put<any>(`${this.url}/${propietarioId}`, formData);
  }

  activeInactivePropietario(propietarioId: number): Observable<any> {
    this.url = this.getUrl('propietario');
    return this.http.patch<any>(`${this.url}/${propietarioId}`, {});
  }

}
