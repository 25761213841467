<div class="d-flex justify-content-center align-items-center">
  <mat-icon class="text-danger">error</mat-icon>
  <h1 mat-dialog-title class="text-danger m-0 px-2"><strong>{{ data.title }}</strong></h1>
</div>
<mat-dialog-content>
  <ng-container *ngFor="let msg of data.message">
    <h3 class="mb-0 mt-2">Archivo: {{ msg.filename }}</h3>
    <li class="text-danger">{{msg.message}}</li>
    <ng-container *ngIf="msg.columnas_faltantes && msg.columnas_faltantes.length > 0">
      <li class="text-danger">Columnas faltantes:
        {{ getColumnsErrors(msg.columnas_faltantes) }}
      </li>
    </ng-container>
    <mat-divider class="my-2"></mat-divider>
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button class="save-button" mat-flat-button color="accent" type="button"(click)="ok()">{{ data.okText }}</button>
</mat-dialog-actions>
