import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {Router} from "@angular/router";

@Component({
  selector: 'app-error-dialog',
  templateUrl: './error-dialog.component.html',
  styleUrls: ['./error-dialog.component.scss']
})
export class ErrorDialogComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { message: any, title: string, okText: string},
    private mdDialogRef: MatDialogRef<ErrorDialogComponent>,
    private route: Router)
  {
    mdDialogRef.disableClose = true;
  }

  public close(value: any) {
    this.mdDialogRef.close(value);
  }

  public ok() {
    this.close(true);
    this.route.routeReuseStrategy.shouldReuseRoute = () => false;
    this.route.onSameUrlNavigation = 'reload';
    this.route.navigate(['/file']);
  }

  public getColumnsErrors(arrErrors: any[]) {
    return arrErrors.join(',');
  }


}
