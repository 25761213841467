export const AUTH_KEY = 'authenticated';
export const USER_KEY = 'me';
export const ESTANCIA = null;
export const VISIBLE_STATUS = {
  SI: 'SI',
  NO: 'NO',
};
export const VISIBLE_STATUS_FILTER_LIST = Object.values(VISIBLE_STATUS);
export const PRINCIPAL_BREAKPOINT = 1280;

