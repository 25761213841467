import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { AUTH_KEY, ESTANCIA } from '../constant';
import { Authenticated } from '../interfaces/authenticated';
import { BaseService } from './base.service';

@Injectable({ providedIn: 'root' })
export class AuthService extends BaseService {

  private authenticatedBehaviorSubject = new BehaviorSubject<Authenticated | null>(
    JSON.parse(localStorage.getItem(AUTH_KEY))
  );

  public get authenticationValue(): Authenticated | null {
    return this.authenticatedBehaviorSubject.value;
  }

  public get token(): string {
    return this.authenticationValue ? this.authenticationValue.access_token : null;
  }

  constructor(private http: HttpClient, private router: Router) {
    super();
  }

  public get isAuthenticated(): boolean {
    return !!this.authenticatedBehaviorSubject.value;
  }

  public isAuthenticatedObservable(): Observable<boolean> {
    return this.getAuthenticatedObservable().pipe(filter(a => !!a), map(a => !!a));
  }

  public getAuthenticatedObservable(): Observable<Authenticated | null> {
    return this.authenticatedBehaviorSubject.asObservable();
  }

  login(
    formData: FormData
  ): Observable<Authenticated> {
    let api = ''
    let estancia = JSON.parse(localStorage.getItem(ESTANCIA))
    if ( estancia == 2){  api = this.api2Url;}
    else if (estancia == 1){ api = this.apiUrl; }
    return this.http
      .post<any>(`${api}login/access-token`, formData)
      .pipe(map((auth) => this.refreshToken(auth)));
  }

  logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem(AUTH_KEY);
    localStorage.removeItem(ESTANCIA);
    localStorage.clear();
    this.authenticatedBehaviorSubject.next(null);
    this.router.navigate(['login']);
  }

  refreshToken(auth: Authenticated): Authenticated {
    // store user details and jwt token in local storage to keep user logged in between page refreshes
    localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
    this.authenticatedBehaviorSubject.next(auth);
    return auth;
  }

  get_code(
    formData: any
  ): Observable<Authenticated> {
    let api = ''
    let estancia = JSON.parse(localStorage.getItem(ESTANCIA))
    if ( estancia == 2){  api = this.api2Url;}
    else if (estancia == 1){ api = this.apiUrl; }
    return this.http
      .post<any>(`${api}login/code`, formData)
      .pipe(map((auth) => this.refreshToken(auth)));
  }

  new_password_login(
    formData: FormData
  ): Observable<Authenticated> {
    let api = ''
    let estancia = JSON.parse(localStorage.getItem(ESTANCIA))
    if ( estancia == 2){  api = this.api2Url;}
    else if (estancia == 1){ api = this.apiUrl; }
    return this.http
      .post<any>(`${api}login/change-password`, formData)
      .pipe(map((auth) => this.refreshToken(auth)));
  }

}
