<div class="col-xs-12 custom-modalbox" style="height: 100%; width: 100%; padding-right: 20px; display: flex; flex-direction: column; align-items: center;">
    <mat-card class="dashboard-card mat-elevation-z8 m-2" style="background-color: #e5e6f0; height: auto; width: 70vw; font-size: smaller; ">
        <ng-container style="width: 100%; " *ngFor="let chunkedKeys of fila(filteredKeys, 5); let i = index">
            <div style="justify-content: space-evenly; width: 100%; padding-top: 5px; text-align: left; padding-left: 25px; padding-right: 25px;" class="row">
                <div [style.height.px]="getRowHeight(chunkedKeys)" *ngFor="let key of chunkedKeys; let j = index" CLASS="col-xs-12 col-md"  >
                    <div [ngClass]="{'two-lines': (key.toUpperCase() + ': ' + obtenerDatosAnimal(animal)[key]).length > 15}" style="max-width: 200px;">
                        <b>{{key.toUpperCase()}}: </b> <span>{{obtenerDatosAnimal(animal)[key]}}</span> 
                    </div>
                </div>
                <div class="col-xs-12 col-md" *ngIf="chunkedKeys.length < 5 && i === fila(filteredKeys, 5).length - 1 && chunkedKeys.length === 4"></div>
                <div class="col-xs-12 col-md" *ngIf="chunkedKeys.length < 5 && i === fila(filteredKeys, 5).length - 1 && chunkedKeys.length === 2"></div>
                <div class="col-xs-12 col-md" *ngIf="chunkedKeys.length < 5 && i === fila(filteredKeys, 5).length - 1 && chunkedKeys.length === 2"></div>
                <div class="col-xs-12 col-md" *ngIf="chunkedKeys.length < 5 && i === fila(filteredKeys, 5).length - 1 && chunkedKeys.length === 2"></div>
                <div class="col-xs-12 col-md" *ngIf="chunkedKeys.length < 5 && i === fila(filteredKeys, 5).length - 1 && chunkedKeys.length === 3"></div>
                <div class="col-xs-12 col-md" *ngIf="chunkedKeys.length < 5 && i === fila(filteredKeys, 5).length - 1 && chunkedKeys.length === 3"></div>
            </div>
        </ng-container>
    </mat-card>
    
    <button class="close-button" mat-button (click)="close()">Cerrar</button>
</div>
