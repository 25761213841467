import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { User } from '../models/user';
import { BaseService } from './base.service';
import { map } from 'rxjs/operators';
import { ESTANCIA, USER_KEY } from '../constant';

@Injectable({
  providedIn: 'root',
})
export class UserService extends BaseService {
  url = `${this.apiUrl}users/`;

  private userBehaviorSubject = new BehaviorSubject<User | null>(
    JSON.parse(localStorage.getItem(USER_KEY))
  );

  private listBehaviorSubject = new BehaviorSubject<User[]>([]);

  constructor(private http: HttpClient) {
    super();
  }

  fetchList(): void {
    this.url = this.getUrl('users/');
    const subscription = this.http
      .get<User[]>(`${this.url}`)
      .subscribe((list) => {
        this.listBehaviorSubject.next(list);
        subscription.unsubscribe();
      });
  }

  getList(): Observable<User[]> {
    this.url = this.getUrl('users/');
    return this.listBehaviorSubject.asObservable();
  }

  me(): Observable<User> {
    this.url = this.getUrl('users/');
    return this.http.get<User>(`${this.url}me`)
      .pipe(map((user) => this.getUserAuth(user)));
  }

  public get userValue(): User | null {
    this.url = this.getUrl('users/');
    return this.userBehaviorSubject.value;
  }

  getUserAuth(user: User): User {
    this.url = this.getUrl('users/');
    // store user details in local storage to keep user logged in between page refreshes
    localStorage.setItem(USER_KEY, JSON.stringify(user));
    this.userBehaviorSubject.next(user);
    return user;
  }

  getPaginate(skip: number = 0, limit: number = 0, sortBy: string, direction: string): Observable<any[]> {
    this.url = this.getUrl('users/');
    let toGet = `${this.url}paginate/?skip=${skip}&limit=${limit}`;
    toGet = sortBy && direction ? toGet.concat(`&sort_by=${sortBy}&direction=${direction}`) : toGet;
    return this.http.get<any[]>(toGet);
  }

  activeInactiveUser(userId: number): Observable<any> {
    this.url = this.getUrl('users/');
    return this.http.patch<any>(`${this.url}${userId}`, {});
  }

  getUser(userId: number): Observable<any> {
    this.url = this.getUrl('users/');
    const user = userId.toString();
    return this.http.get<any>(`${this.url}${user}`);
  }

  updateUser(userId: number, formData): Observable<any> {
    this.url = this.getUrl('users/');
    return this.http.put<any>(`${this.url}${userId}`, formData);
  }

  saveUser(formData): Observable<any> {
    this.url = this.getUrl('users/');
    return this.http.post<any>(`${this.url}`, formData);
  }
}
