import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { MenuItem } from '../interfaces/menu-item';

@Injectable({
  providedIn: 'root'
})
export class MenuConfigService {

  private toggleSidebarMenuBehaviorSubject = new BehaviorSubject<boolean>(false);

  constructor() { }

  getToggleSidebarMenuObservable(): Observable<boolean> {
    return this.toggleSidebarMenuBehaviorSubject.asObservable();
  }

  isSidebarMenuOpened(): boolean {
    return this.toggleSidebarMenuBehaviorSubject.value;
  }

  setSidebarMenu(value: boolean): void {
    this.toggleSidebarMenuBehaviorSubject.next(value);
  }

  toggleSidebarMenu(): void {
    this.toggleSidebarMenuBehaviorSubject.next(!this.toggleSidebarMenuBehaviorSubject.value);
  }
}
