import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnimalService } from 'src/app/services/animal.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-dialog-animal-ficha',
  templateUrl: './dialog-animal-ficha.component.html',
  styleUrls: ['./dialog-animal-ficha.component.scss']
})
export class DialogAnimalFichaComponent implements OnInit {
  id: number;
  animal: any;
  errorMessage = 'Error al intentar ver el animal.';
  allowedKeys = [
    "ide", "sitrap", "idv", "fecha nacimiento", "carimbo",
    "madre hbp", "padre", "sexo", "categoria", "raza",
    "registro", "variedad", "lote", "clasificacion", "origen",
    "estado", "peso", "propietario", "madre idv", "peso nacimiento",
    "potrero", "lote nacimiento", "fecha mortandad", "causa mortandad", "fecha destete",
    "peso destete", "fecha consumo", "peso consumo", "peso gancho", "fecha salida",
    "destino", "peso salida"
  ];
  constructor(
    private dialogRef: MatDialogRef<DialogAnimalFichaComponent>,
    private animalService: AnimalService,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
      this.id = data;
     }

     ngOnInit(): void {
      // console.log('this.data; ',this.data);
      
      this.spinner.show();
      this.animalService.getView(this.data).subscribe((data: any) => {
        
        if (data) {
          // this.animal = data;
          switch (data.animal.estado) {
            case "V":
              data.animal.estado = "VIVO";
              break;
            case "S":
              data.animal.estado = "SALIDA";
              break;
            case "C":
              data.animal.estado = "CONSUMO"; 
              break;
            case "M":
              data.animal.estado = "MORTANDAD";
              break;
            default:
              data.animal.estado = "VIVO";
              break;
          }
        }
        this.animal = data;
        this.spinner.hide();
        // console.log(data);
      }, error => {
        this.spinner.hide();
        console.error(error);
      });
    }
    

  keys(obj: any): string[] {
    return Object.keys(obj);
  }

  fila(array: any[], size: number): any[][] {
    const filteredArray = array.filter(item =>
      this.allowedKeys.some(key =>
        (item[key] !== null && item[key] !== '-')
      )
    );


    const chunkedArr = [];
    let index = 0;
    while (index < filteredArray.length) {
      chunkedArr.push(filteredArray.slice(index, size + index));
      index += size;
    }
    return chunkedArr;
  }

  obtenerDatosAnimal(objeto: any): any {
    const datosAnimal = {
      "ide": objeto?.animal?.ide,
      "sitrap": objeto?.animal?.sitrap,
      "idv": objeto?.animal?.idv,
      "fecha nacimiento": objeto?.animal?.fecha_nacimiento_at_format,
      "carimbo": objeto?.animal?.carimbo,
      "madre hbp": objeto?.animal?.madre_hbp,
      "padre": objeto?.animal?.padre,
      "sexo": objeto?.animal?.sexo,
      "categoria": objeto?.animal?.categoria?.descripcion,
      "raza": objeto?.animal?.raza,
      "registro": objeto?.animal?.registro,
      "variedad": objeto?.animal?.variedad,
      "lote": objeto?.animal?.lote?.descripcion,
      "clasificacion": objeto?.animal?.clasificacion,
      "origen": objeto?.animal?.origen,
      "estado": objeto?.animal?.estado,
      "peso": objeto?.animal?.peso,
      // "propietario": objeto?.animal?.propietario?.descripcion,
      "madre idv": objeto?.nacimiento?.madre_idv,
      "peso nacimiento": objeto?.nacimiento?.peso,
      "potrero": objeto?.nacimiento?.potrero?.descripcion,
      "lote nacimiento": objeto?.nacimiento?.lote?.descripcion,
      "fecha mortandad": objeto?.mortandad?.fecha_at_format,
      "causa mortandad": objeto?.mortandad?.causa,
      "fecha destete": objeto?.destete?.fecha_at_format,
      "peso destete": objeto?.destete?.peso,
      "fecha consumo": objeto?.consumo?.fecha_at_format,
      "peso consumo": objeto?.consumo?.peso,
      "peso gancho": objeto?.consumo?.peso_gancho,
      "fecha salida": objeto?.salida?.fecha_at_format,
      "destino": objeto?.salida?.destino,
      "peso salida": objeto?.salida?.peso
    };
    return datosAnimal;
  }

  get filteredKeys(): string[] {
    const datosAnimal = this.obtenerDatosAnimal(this.animal);
    return this.keys(datosAnimal).filter(key =>
      this.allowedKeys.includes(key) && datosAnimal[key] !== null && datosAnimal[key] !== undefined && datosAnimal[key] !== '-'
    );
  }

  getRowHeight(chunkedKeys: any[]): number {
    for (let key of chunkedKeys) {
      if ((key.toUpperCase() + ': ' + this.obtenerDatosAnimal(this.animal)[key]).length > 15) {
        return 40; // Doble de la altura original, ajusta según sea necesario
      }
    }
    return 20; // Altura original
  }

  close() {
    this.dialogRef.close();
  }

}
