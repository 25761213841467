<div class="d-flex justify-content-center align-items-center">
  <mat-icon class="text-success">check_circle</mat-icon>
  <h1 mat-dialog-title class="text-success m-0 px-2"><strong>{{ data.title }}</strong></h1>
</div>
<mat-dialog-content>
    <h3 class="mb-0 mt-2">{{ data.message }}</h3>
</mat-dialog-content>
<mat-dialog-actions class="d-flex justify-content-end">
  <button class="save-button" mat-flat-button color="accent" type="button"(click)="ok()">{{ data.okText }}</button>
</mat-dialog-actions>
