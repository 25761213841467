import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { MaterialModule } from '../material/material.module';
import { HeaderComponent } from './header/header.component';
import { HttpErrorSnackBarComponent } from './http-error-snack-bar/http-error-snack-bar.component';
import { SelectCheckAllComponent } from './select-check-all/select-check-all.component';
import { DialogConfirmationComponent } from './dialog-confirmation/dialog-confirmation/dialog-confirmation.component';
import { NgChartsModule } from 'ng2-charts';
import { ErrorDialogComponent } from './error-dialog/error-dialog.component';
import { SuccessDialogComponent } from './success-dialog/success-dialog.component';
import { SidenavFilterComponent } from './sidenav-filter/sidenav-filter.component';
import { MultiDatepickerModule } from './multidatepicker/multidatepicker.module';
import { DialogAnimalFichaComponent } from './dialog-animal-ficha/dialog-animal-ficha.component';

@NgModule({
  declarations: [
    HttpErrorSnackBarComponent,
    HeaderComponent,
    SelectCheckAllComponent,
    DialogConfirmationComponent,
    ErrorDialogComponent,
    SuccessDialogComponent,
    SidenavFilterComponent,
    DialogAnimalFichaComponent,
  ],
  exports: [
    HttpErrorSnackBarComponent,
    HeaderComponent,
    SelectCheckAllComponent,
    DialogConfirmationComponent,
    SidenavFilterComponent,
    MultiDatepickerModule
  ],
    imports: [
      FormsModule,
      CommonModule,
      LayoutModule,
      MaterialModule,
      RouterModule,
      NgChartsModule,
      ReactiveFormsModule,
      PortalModule,
      MultiDatepickerModule,
    ],
})
export class SharedModule { }
