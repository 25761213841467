import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { ESTANCIA } from 'src/app/constant';
import { User } from 'src/app/models/user';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { HttpErrorSnackBarComponent } from 'src/app/shared/http-error-snack-bar/http-error-snack-bar.component';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  forgotPasswordCard = false;
  loginCard = true;
  sendCodeCard = false;
  showPassword = false;
  estancia: any = null;
  estancias = [{"nombre" : "7 PALMAS", "number" : "1" },
            {"nombre" : "PRIMAVERA", "number" : "2" },]
  loginForm = this.fb.group({
    estancia: [this.estancia, [Validators.required, Validators.minLength(1)]],
    username: [null, Validators.required],
    password: [null, Validators.required],
  });
  codeForm = this.fb.group({
    estancia: [this.estancia, [Validators.required, Validators.minLength(1)]],
    email: [null, Validators.required]
  });
  newPasswordForm = this.fb.group({
    code: [null, Validators.required],
    new_password: [null, Validators.required],
  });

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private matSnackBar: MatSnackBar
  ) { }

  ngOnInit(): void {
    localStorage.setItem(ESTANCIA, null); }

  onSubmit(): void {
    localStorage.setItem(ESTANCIA, this.loginForm.get('estancia').value);
    const formData = new FormData();
    formData.append('username', this.loginForm.get('username').value);
    formData.append('password', this.loginForm.get('password').value);
    formData.append('estancia', this.loginForm.get('estancia').value);
    if(this.loginForm.get('estancia').value != null){
    this.authService
      .login(formData)
      .subscribe(() => {
        this.userService.me().subscribe((resp: User) => {
          this.router.navigate(['/']);
        });
      }, errors => {
        this.matSnackBar.openFromComponent(HttpErrorSnackBarComponent, {
          panelClass: ['http-error-snackbar'],
          data: ['Usuario o contraseña inválidos'],
        });
      });}
  }

  showPasswordCard():void {
    localStorage.setItem(ESTANCIA, this.loginForm.get('estancia').value);
    const formData = new FormData();
    formData.append('username', this.codeForm.get('email').value);
    formData.append('password', 'password');
    this.authService
    .get_code(formData)
    .subscribe(() => {
      this.forgotPasswordCard = true;
      this.loginCard=false;
      this.sendCodeCard = false;
    }, errors => {
      this.matSnackBar.openFromComponent(HttpErrorSnackBarComponent, {
        panelClass: ['http-error-snackbar'],
        data: ['Usuario inválido'],
      });
    });
  }

  showSendCodeCard():void {
    this.forgotPasswordCard = false;
    this.loginCard=false;
    this.sendCodeCard=true;
  }

  changePassword():void {
    const formData = new FormData();
    formData.append('username', this.newPasswordForm.get('code').value);
    formData.append('password', this.newPasswordForm.get('new_password').value);
    this.authService
      .new_password_login(formData)
      .subscribe(() => {
        this.userService.me().subscribe((resp: User) => {
          this.openSnackBar('La contraseña fue reestablecida!', 'OK');
          this.router.navigate(['/']);
        });
      }, errors => {
        this.matSnackBar.openFromComponent(HttpErrorSnackBarComponent, {
          panelClass: ['http-error-snackbar'],
          data: ['Código o contraseña inválidos'],
        });
      });
  }

  openSnackBar(message, action) {
    this.matSnackBar.open(message, action, {
    });
  }

}
