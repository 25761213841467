import { environment } from 'src/environments/environment';
import { ESTANCIA } from '../constant';


export class BaseService {
  apiUrl = environment.apiUrl;
  api2Url = environment.api2Url;

  constructor() { }

  getUrl(window:string): string {
    let estancia = JSON.parse(localStorage.getItem(ESTANCIA))
    if ( estancia == 2){  return this.api2Url+window;}
    else if (estancia == 1){ return this.apiUrl+window; }
  }
}
