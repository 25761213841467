<app-year-picker *ngIf="mode === 'YEAR'"
                 [formControl]="yearPickerCtrl"
                 [jpCustomFormFieldClass]="jpCustomFormFieldClass"
                 [label]="label"
                 [max]="max"
                 [min]="min"
                 [touchUi]="touchUi">
</app-year-picker>

<app-month-picker *ngIf="_showMonthPicker"
                  [formControl]="monthPickerCtrl"
                  [jpCustomFormFieldClass]="jpCustomFormFieldClass"
                  [label]="label"
                  [max]="max"
                  [min]="min"
                  [mode]="mode"
                  [touchUi]="touchUi">
</app-month-picker>

<app-regular-datepicker *ngIf="_showRegularDatepicker"
                        [formControl]="regularPickerCtrl"
                        [jpCustomFormFieldClass]="jpCustomFormFieldClass"
                        [label]="label"
                        [max]="max"
                        [min]="min"
                        [mode]="mode"
                        [touchUi]="touchUi">
</app-regular-datepicker>
