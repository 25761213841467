import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ESTANCIA } from '../constant';

@Injectable({
  providedIn: 'root'
})
export class AnimalService extends BaseService {
  url = `${this.apiUrl}animal`;

  constructor(private http: HttpClient) {
    super();
  }

  getReporteExistencia(filter = {}): Observable<any[]> {
    this.url = this.getUrl('animal');
    return this.http.get<any[]>(`${this.url}/existencia/?${filter}`);
  }

  getReportePotrero(filter = {}): Observable<any[]> {
    this.url = this.getUrl('animal');
    return this.http.get<any[]>(`${this.url}/existencia/potrero/?${filter}`);
  }

  getAnimalList(skip: number = 0, limit: number = 0, sortBy: string, direction: string, filtros: any = ''): Observable<any[]> {
    this.url = this.getUrl('animal');
    let toGet = `${this.url}/paginate/?skip=${skip}&limit=${limit}`;
    toGet = sortBy && direction ? toGet.concat(`&sort_by=${sortBy}&direction=${direction}`) : toGet;
    toGet = toGet.concat(`&${filtros}`);
    return this.http.get<any[]>(toGet);
  }

  getListParents(): Observable<any[]> {
    this.url = this.getUrl('animal');
    return this.http.get<any[]>(`${this.url}/parents`);
  }

  delete(id: number): Observable<any> {
    this.url = this.getUrl('animal');
    return this.http.delete(`${this.url}/${id}`);
  }

  downloadCSV(filter = {}, sortBy: string, direction: string, cabezal: boolean = true): Observable<HttpResponse<any>> {
    this.url = this.getUrl('animal');
    let toGet = `${this.url}/csv/?cabezal=${cabezal}`;
    toGet = sortBy && direction ? toGet.concat(`&sort_by=${sortBy}&direction=${direction}`) : toGet;
    toGet = toGet.concat(`&${filter}`);
    return this.http.get(toGet, {
      observe: 'response',
      responseType: 'blob'});
  }

  downloadPDF(filter = {}): Observable<HttpResponse<any>> {
    this.url = this.getUrl('animal');
    return this.http.get(`${this.url}/existencia/reportepdf/?${filter}`, {
      observe: 'response',
      responseType: 'blob'});
  }

  getView(animalId: number): Observable<any> {
    this.url = this.getUrl('animal');
    const animal = animalId.toString();
    return this.http.get<any>(`${this.url}/ver/${animal}`);
  }

  mergeAnimals(animal1: string, animal2 : string): Observable<any> {
    this.url = this.getUrl('animal');
    return this.http.get<any>(`${this.url}/merge?animal1=${animal1}&animal2=${animal2}`);
  }

  trasladarAnimales(animal1: [], animal2 : string): Observable<any> {
    this.url = this.getUrl('animal');
    return this.http.get<any>(`${this.url}/trasladar?animal1=${animal1}&animal2=${animal2}`);
  }

  bajaAnimals(animal1: string, estado: string): Observable<any> {
    this.url = this.getUrl('animal');
    return this.http.get<any>(`${this.url}/baja?animal1=${animal1}&estado=${estado}`);
  }

}
