import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { ESTANCIA } from '../constant';

@Injectable({
  providedIn: 'root',
})
export class PotreroService extends BaseService {
  url = `${this.apiUrl}potrero`;

  constructor(private http: HttpClient) {
    super();
  }

  getPotrero(potreroId: number): Observable<any> {
    this.url = this.getUrl('potrero');
    const potrero = potreroId.toString();
    return this.http.get<any>(`${this.url}/${potrero}`);
  }

  getList(): Observable<any[]> {
    this.url = this.getUrl('potrero');
    return this.http.get<any[]>(`${this.url}`);
  }

  getPaginate(skip: number = 0, limit: number = 0, sortBy: string, direction: string): Observable<any[]> {
    this.url = this.getUrl('potrero');
    let toGet = `${this.url}/paginate/?skip=${skip}&limit=${limit}`;
    toGet = sortBy && direction ? toGet.concat(`&sort_by=${sortBy}&direction=${direction}`) : toGet;
    return this.http.get<any[]>(toGet);
  }

  savePotrero(formData): Observable<any> {
    this.url = this.getUrl('potrero');
    return this.http.post<any>(`${this.url}/`, formData);
  }

  updatePotrero(potreroId: number, formData): Observable<any> {
    this.url = this.getUrl('potrero');
    return this.http.put<any>(`${this.url}/${potreroId}`, formData);
  }

  activeInactivePotrero(potreroId: number): Observable<any> {
    this.url = this.getUrl('potrero');
    return this.http.patch<any>(`${this.url}/${potreroId}`, {});
  }

}
